import './About.css';
import React, { useEffect } from 'react';

export default function About(){

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <div>
                <h1 className="font-mono justify-center text-center align-middle">About</h1>
            </div>
            <div className="bg-white shadow-lg p-8 rounded-lg mt-4 mb-8">
                <p>Hello! I'm Andy, an avid technologist and tinkerer. I thrive on experimentation and often find myself developing incredibly obscure and niche things. My passion for technology is matched only by my curiosity and eagerness to explore the boundaries of what's possible.</p>
                <br />
                <p>Whether it's developing software, crafting hardware solutions, or blending the digital with the physical, I am always seeking out new avenues to apply my skills and expand my knowledge. This website serves as a living portfolio—a chronicle of my continuous learning process and a showcase of my endeavors in various tech domains.</p>
                <br />
                <p>Thank you for visiting! I hope my work inspires and excites you as much as it does me. If you're interested in collaboration or just want to chat about tech, don't hesitate to reach out at andy at peasnrice dot com.</p>
            </div>
        </div>
    );
}
