import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from './Components/App/App';
import About from './Components/About/About';
import Home from './Components/Home/Home';
import Work from './Components/Work/Work';
import ProjectsPage from './Components/ProjectsPage/ProjectsPage';
import Project from './Components/Project/Project';
import Play from './Components/Play/Play';
import './index.css';
import reportWebVitals from './reportWebVitals';

const rootElement = document.getElementById('app');
ReactDOM.render(
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/work" element={<Work />} />
          <Route path="/projects/:id" element={<Project />} />
          <Route path="/projects" element={<ProjectsPage />} />
          <Route path="/play" element={<Play />} />
        </Route>
      </Routes>
    </BrowserRouter>,
  rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();