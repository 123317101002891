import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    useQuery,
    gql,
    createHttpLink
} from "@apollo/client";

const CONTENTFUL_URL = `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_SPACE}`
const httpLink = createHttpLink({
    fetch,
    uri: CONTENTFUL_URL,
    headers: {
        authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
        'Content-Language': 'en-us',
    },
});

const client = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache()
});

function renderOptions(links) {

    // create an asset map
    const assetMap = new Map();
    // loop through the assets and add them to the map
    for (const asset of links.assets.block) {
        assetMap.set(asset.sys.id, asset);
    }

    return {
        renderNode: {
            [BLOCKS.HEADING_2]: (node, children) => (
                <h2 className="text-4xl mt-4 mb-4">{children}</h2>
            ),
            [BLOCKS.HEADING_3]: (node, children) => (
                <h3 className="text-2xl mt-4 mb-4">{children}</h3>
            ),
            [BLOCKS.PARAGRAPH]: (node, children) => (
                <p className="text-base mt-2 mb-2">{children}</p>
            ),
            [INLINES.HYPERLINK]: (node, children) => {
                if((node.data.uri).includes("player.vimeo.com/video")){
                  return <iframe class="w-full aspect-video" title={children} src={node.data.uri} frameBorder="0" allowFullScreen></iframe>
                } else if((node.data.uri).includes("youtube.com/embed")) {
                  return <iframe class="w-full aspect-video" title={children} src={node.data.uri} allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" frameBorder="0" allowFullScreen></iframe>
                } else {
                    return <a href={node.data.uri} target='_blank' rel='noopener noreferrer' className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600">{children}</a>
                }
            },
            [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
                const asset = assetMap.get(node.data.target.sys.id);
                return (
                    <div className="p-4">
                        <img className="max-h-lg object-cover m-auto rounded-lg" src={asset.url} alt={asset.description} />
                    </div>
                );
            }
        }
    }
};

function ProjectDetail(dat) {
    console.log("id: " + dat.projectID);
    const { loading, error, data } = useQuery(gql`
    {
        project(id: "${dat.projectID}") {
            sys {
                id
            }
            title
            type
            shortDescription
            publishDate
            featuredImage {
                title
                description
                fileName
                size
                url
                width
                height
            }
            projectContent {
                json
                links {
                    assets {
                        block {
                            sys {
                                id
                            }
                            url
                            title
                            width
                            height
                            description
                        }
                    }
                }
            }
        }
    } 
    `);


    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :( {error.message}</p>;


    return (
            <div className="">
                <h1>{data.project.title}</h1>
                <div className="bg-white shadow-lg p-8 rounded-lg mt-4 mb-8">
                    {documentToReactComponents(data.project.projectContent.json, renderOptions(data.project.projectContent.links))}
                </div>
            </div>
    );
}


const Project = () => {
    const { id } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <div className="Project">
            <ApolloProvider client={client}>
                <ProjectDetail projectID={id} />
            </ApolloProvider>
        </div>
    );
}

export default Project;