import './App.css';
import * as React from 'react';
import { Outlet, Link, useLocation } from 'react-router-dom';
import { FaHome, FaInfoCircle, FaCode } from 'react-icons/fa';

export default function App() {
  const location = useLocation();
  console.log(location.pathname);
  return (
    <div className="bg-teal-100 bg-cover flex flex-col h-screen">
      <div className="max-w-full bg-blue-600 sticky top-0">
        <div className="max-w-6xl mx-auto px-4 ">
          <div className="flex justify-between">
            <div className="flex space-x-7">
              <nav className="hidden md:flex items-center space-x-1">
                <Link className={`py-4 px-2 text-white border-b-4 font-semibold hover:text-red-300 transition duration-300 hover:border-red-300 ${location.pathname === '/' ? 'border-red-300 text-red-300' : 'text-white border-blue-300'}`} to="/">Home<FaHome className="inline-block pl-1"/></Link> 
                <Link className={`py-4 px-2 text-white border-b-4 font-semibold hover:text-red-300 transition duration-300 hover:border-red-300 ${location.pathname === '/about' ? 'border-red-300 text-red-300' : 'text-white border-blue-300'}`} to="/about">About<FaInfoCircle className="inline-block pl-1"/></Link>
                {/* <Link className={`py-4 px-2 text-white border-b-4 font-semibold hover:text-red-300 transition duration-300 hover:border-red-300 ${location.pathname.includes('/work') ? 'border-red-300 text-red-300' : 'text-white border-blue-300'}`} to="/work">Work<FaBriefcase className="inline-block pl-1"/></Link> */}
                <Link className={`py-4 px-2 text-white border-b-4 font-semibold hover:text-red-300 transition duration-300 hover:border-red-300 ${location.pathname.includes('/projects') ? 'border-red-300 text-red-300' : 'text-white border-blue-300'}`} to="/projects">Projects<FaCode className="inline-block pl-1"/></Link>
                {/* <Link className={`py-4 px-2 text-white border-b-4 font-semibold hover:text-red-300 transition duration-300 hover:border-red-300 ${location.pathname.includes('/play') ? 'border-red-300 text-red-300' : 'text-white border-blue-300'}`} to="/play">Play<FaGamepad className="inline-block pl-1"/></Link> */}
              </nav>
            </div>
          </div>
        </div>
        </div>  
        <div className="bg-teal-100 bg-cover md:grid md:grid-cols-6 md:gap-4 md:mt-8 flex-grow">
          <div className="md:col-start-2 md:col-span-4">
            <Outlet />
          </div>
        </div>
        <div className="md:hidden max-w-full bg-blue-600 min-h-[60px] sticky bottom-0">
          <div className="grid grid-rows-4 grid-flow-col gap-4 max-h-[60px]">
              <nav className="mx-auto pt-[18px]">
                <Link className={`m-1 py-4 px-2 text-white border-t-4 font-semibold hover:text-red-300 transition duration-300 hover:border-red-300 ${location.pathname === '/' ? 'border-red-300 text-red-300' : 'text-white border-blue-300'}`} to="/">Home<FaHome className="inline-block pl-1"/></Link> 
                <Link className={`m-1 py-4 px-2 text-white border-t-4 font-semibold hover:text-red-300 transition duration-300 hover:border-red-300 ${location.pathname === '/about' ? 'border-red-300 text-red-300' : 'text-white border-blue-300'}`} to="/about">About<FaInfoCircle className="inline-block pl-1"/></Link>
                {/* <Link className={`py-4 px-2 text-white border-t-4 font-semibold hover:text-red-300 transition duration-300 hover:border-red-300 ${location.pathname.includes('/work') ? 'border-red-300 text-red-300' : 'text-white border-blue-300'}`} to="/work">Work<FaBriefcase className="inline-block pl-1"/></Link> */}
                <Link className={`m-1 py-4 px-2 text-white border-t-4 font-semibold hover:text-red-300 transition duration-300 hover:border-red-300 ${location.pathname.includes('/projects') ? 'border-red-300 text-red-300' : 'text-white border-blue-300'}`} to="/projects">Projects<FaCode className="inline-block pl-1"/></Link>
                {/* <Link className={`py-4 px-2 text-white border-t-4 font-semibold hover:text-red-300 transition duration-300 hover:border-red-300 ${location.pathname.includes('/play') ? 'border-red-300 text-red-300' : 'text-white border-blue-300'}`} to="/play">Play<FaGamepad className="inline-block pl-1"/></Link> */}
            </nav>
          </div>
      </div>
    </div>
  );
}
