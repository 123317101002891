import './ProjectsPage.css';

import React from 'react';
import { Link } from 'react-router-dom';
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    useQuery,
    gql,
    createHttpLink
} from "@apollo/client";

import moment from 'moment';

const CONTENTFUL_URL = `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_SPACE}`

const httpLink = createHttpLink({
    fetch,
    uri: CONTENTFUL_URL,
    headers: {
        authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
        'Content-Language': 'en-us',
    },
});

const client = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache()
});

function DisplayProjectList() {
    const { loading, error, data } = useQuery(gql`
    {
        projectCollection (order: [publishDate_DESC]) {
            items {
                sys {
                    id
                }
                title
                type
                shortDescription
                publishDate
                featuredImage {
                    title
                    description
                    fileName
                    size
                    url
                    width
                    height
                    }
                }
            }
        }
    `);


    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :( {error.message}</p>;


    return data.projectCollection.items.map((op) => (
        <div className="max-w-md mx-auto bg-white m-2 sm:rounded-xl shadow-md overflow-hidden sm:max-w-2xl" key={op.sys.id}>
            <Link className={"md:flex"} to={`${op.sys.id}`}>
                <div className={"md:shrink-0"}>
                    <img className="h-48 w-full object-cover md:h-full md:w-48" src={op.featuredImage.url} alt="featured" />
                </div>
                <div className="p-6">
                    <h5 className="project-title text-gray-900 text-xl font-medium mb-2">
                        {op.title ? op.title : "Unknown Title"}
                    </h5>
                    <p className="text-gray-700 text-base mb-4">
                        {op.shortDescription}
                    </p>
                    <p className="px-2 py-1 rounded-full border border-gray-300 text-gray-500 font-semibold text-xs flex align-center w-max">
                        {op.type}
                    </p>
                    <p className="my-4 text-gray-500 font-semibold text-xs flex align-center w-max">
                        published on {moment(op.publishDate).format('L')}
                    </p>
                </div>
            </Link>
        </div>
    ));
}


export default class ProjectsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectValue: 'category'
        }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        var fakeEvent = {
            target: {
                value: this.state.selectValue,
            }
        }
        window.scrollTo(0, 0);
        this.handleChange(fakeEvent);
    }

    handleChange(e) {
        var categories = {};
        var isCategory = false;
        this.setState({
            selectValue: e.target.value,
            categories: categories,
            isCategory: isCategory,
        });
    }

    render() {
        if (!client) {
            return <p>Loading</p>
        }
        return (
            <div>
                <h1>Projects</h1>
                <div>
                    <ApolloProvider client={client}>
                        <DisplayProjectList />
                    </ApolloProvider>
                </div>
            </div>
        );
    }
}