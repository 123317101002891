import './Home.css';
import ProfilePic from "../../images/profile.jpg";
import React, { useEffect } from 'react';

export default function Home(){

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <h1 className="font-mono justify-center text-center align-middle">Hello.<br/>I am Andy.</h1>
            <div className="group relative w-64 h-auto md:w-80 md:h-auto mx-auto">
                <img className="absolute md:rounded-full rounded-full" src={ProfilePic} alt="andy-face"></img>
                <div className="hidden group-hover:block absolute w-10 h-10 top-[110px] left-[75px] md:w-14 md:h-14 md:top-[130px] md:left-[90px] rounded-full bg-white"></div>
                <div className="hidden group-hover:block absolute w-3 h-3 top-[117px] left-[89px] md:w-4 md:h-4 md:top-[145px] md:left-[115px] rounded-full bg-black"></div>
                <div className="hidden group-hover:block absolute w-10 h-10 top-[110px] left-[135px] md:w-14 md:h-14 md:top-[130px]  md:left-[170px] rounded-full bg-white"></div>
                <div className="hidden group-hover:block absolute w-3 h-3 top-[125px] left-[147px] md:w-4 md:h-4 md:top-[150px]  md:left-[205px] rounded-full bg-black"></div>
            </div>
        </div>
    );
}
